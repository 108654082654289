import React, { Component } from "react";
import ReactDOM from "react-dom";
// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import auth from "services/auth";
import PropTypes from "prop-types";
import _ from "lodash";

// core components
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
// reactstrap components
import {
  Input
} from "reactstrap";


class TabelaRequisicao  extends Component  {
  constructor(props) {
    super(props);
    this.state = { frota: [] };
  }

  state = {
      color:0,
  }


   async componentDidMount() {
    
  }

  render() {
  return (<div>
    <ReactTable
      data={this.props.requisicoes.map((prop, key) => {
          return {
            id: key,
            idClienteAbastUnico: prop[0],
            Placa: prop[1],
            DataCadastro: prop[2],
            ValidoAte: prop[3],
            ValorLiberado: prop[4],
            QrCode: prop[5],
            flgAcumulaSaldo: (
              // we've added some custom button actions
              <div className="actions-center">
                 <Input type="checkbox" 
                            name="vFilhos"
                            id="vFilhos" 
                            //defaultValue = {prop[3] === 'true'}
                            checked =  {prop[6] === 'true'}
                            readOnly
                          />       
               
                 </div>
                ),
            actions: (
              <div className="actions-right">
                <Button
                  justIcon
                  round
                  simple
                  onClick={() =>
                    window.location.href = '/admin/visualizarequisicao/'+prop[0]
                  }
                  color="warning"
                  className="edit"
                >
                  <Dvr />
                </Button>{" "}
              </div>
            )
          };
        })}
        columns={[
          {
            Header: "Placa",
            accessor: "Placa",
          },
         {
            Header: "Data Cadastro",
            accessor: "DataCadastro"
          },
          {
            Header: "Valido Ate",
            accessor: "ValidoAte"
          },
          {
            Header: "Valor Liberado",
            accessor: "ValorLiberado",
          },
          {
            Header: "Acumula Saldo",
            accessor: "flgAcumulaSaldo",
            type:"checkbox"
          },
      {
            Header: "Editar",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      />
      </div>
  );
}
}

TabelaRequisicao.propTypes = {
  requisicoes: PropTypes.array}

export default TabelaRequisicao;
