import React, { Component, } from "react";
import Datetime from "react-datetime";
import auth from "services/auth";
import GridContainer from "components//Grid/GridContainer.js";
import GridItem from "components//Grid/GridItem.js";
//import Card from "../Card/Card.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import checatoken from "./checatoken";
import CardBody from "components/Card/CardBody.js";
import NavPills from "components/NavPills/NavPills.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Assignment from "@material-ui/icons/Assignment";
import TabelaRequisicao from "./TabelaRequisicao";
import Primary from "components/Typography/Primary.js";
import Muted from "components/Typography/Muted";
import Button from "components/CustomButtons/Button.js";
import classNames from "classnames";
import { Label, Row, Col, Input, FormGroup, Container } from "reactstrap";
import Icon from "@material-ui/core/Icon";
import CardFooter from "components/Card/CardFooter.js";
import DateRange from "@material-ui/icons/DateRange";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Done from "@material-ui/icons/Done";
import Search from "@material-ui/icons/Search";
import AddBox from "@material-ui/icons/AddBox";


const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

export const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

const flexContainerClasses = classNames({
  [classNames.flexContainer]: true,
  [classNames.horizontalDisplay]: true,
});

class CompReqAbast extends Component {

  constructor(props) {
    super(props);

    this.state = {
      motorista: [],
      requisicoes: [],
      edtStatus: "0",
      VencInicio: "",
      VencTermino: "",
    };


    this.SalvaStatus = this.SalvaStatus.bind(this);
    this.SalvaVencInicio = this.SalvaVencInicio.bind(this);
    this.SalvaVencTermino = this.SalvaVencTermino.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.NovaRequisicao = this.NovaRequisicao.bind(this);

  }

  SalvaVencTermino(date) {
    try {
      this.setState({ VencTermino: date.format('YYYY-MM-DD') });
    }
    catch (error) {
      this.setState({ VencTermino: '' });
    }
  }

  SalvaVencInicio(date) {
    console.log(date);
    try {
      this.setState({ VencInicio: date.format('YYYY-MM-DD') });
    }
    catch (error) {
      this.setState({ VencInicio: '' });
    }
  }

  SalvaStatus(event) {
    this.setState({ edtStatus: event.target.value.toUpperCase() });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.getFaturas();
  }

  NovaRequisicao(){
    window.location.href = '/admin/visualizarequisicao/0' 
  }

  async componentDidMount() {
    checatoken();
     const lastSearch = JSON.parse(localStorage.getItem('CompReq'));
     if (lastSearch) {
     this.setState({requisicoes : lastSearch})
     }
  }


  async getFaturas() {
    const chave = localStorage.getItem("lukos-token-fw");
    const response = await auth.get("/faturaweb/GetRequisicoes", {
      headers: {
        token: chave,
        Status: this.state.edtStatus,
        VencIni: this.state.VencInicio,
        VencTer: this.state.VencTermino,
      },
    });
    this.setState({ requisicoes: response.data })
    localStorage.setItem('CompReq', JSON.stringify(this.state.requisicoes ));
  }



  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <Icon><Done /></Icon>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <Container justify="center">
                  <Primary>
                    <h4>Status da Requisição</h4>
                  </Primary>
                </Container>
                <GridContainer justify="space-between"
                  alignItems="center"
                  direction="row"  >
                  <FormGroup check className="form-check-radio"  style ={{ marginLeft: '20px' }}>
                    <Label check>
                      <Input
                        id="vAtivo"
                        name="vAtivo"
                        type="radio"
                        checked={this.state.edtStatus === "0"}
                        onChange={this.SalvaStatus}
                        value={0}
                      />
                      <span className="form-check-sign" />
                      Em Aberto
                    </Label>
                  </FormGroup>
                  <FormGroup check className="form-check-radio" style ={{ marginRight: '20px' }}>
                    <Label check>
                      <Input
                        id="vAtivo"
                        name="vAtivo"
                        type="radio"
                        checked={this.state.edtStatus === "1"}
                        onChange={this.SalvaStatus}
                        value={"1"}
                      />
                      <span className="form-check-sign" />
                      Finalizadas
                    </Label>
                  </FormGroup>
                </GridContainer>
              </CardBody>
              <CardFooter stats>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <DateRange />
              </CardIcon>
              </CardHeader>
              <CardBody>
                <Primary>
                  <h4>Data de Validade</h4>
                </Primary>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <InputLabel >Data Inicial</InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        id="edtVencInicio"
                        name="edtVencInicio"
                        closeOnSelect="true"
                        locale="pt-br"
                        dateFormat="DD/MM/yyyy"
                        onChange={this.SalvaVencInicio}
                        timeFormat={false}
                        inputProps={{ placeholder: "Escolha a data inicial" }}
                      />
                    </FormControl>
                    <br />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <InputLabel >Data Final</InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        id="edtVencTermino"
                        name="edtVencTermino"
                        closeOnSelect="true"
                        locale="pt-br"
                        dateFormat="DD/MM/yyyy"
                        onChange={this.SalvaVencTermino}
                        timeFormat={false}
                        inputProps={{ placeholder: "Escolha a data Final" }}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>

              </CardBody>

            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Search />
              </CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center"
                  alignItems="center"
                  direction="row" >
                  <Button color="behance" width="100%" onClick={this.handleSubmit}> <Search /> Localizar </Button>
                </GridContainer>
                <GridContainer justify="center"
                  alignItems="center"
                  direction="row" >
                <Button color="primary" width="100%" onClick={this.NovaRequisicao}> <AddBox /> Nova Requisição </Button>
                </GridContainer>
              </CardBody>

            </Card>

          </GridItem>


          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Assignment />
                </CardIcon>
                <Muted>
                  <h4>Requisições</h4>
                </Muted>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>

                    </GridItem>
                  </GridContainer>
                </GridItem>
              </CardHeader>
              <CardBody>
                <TabelaRequisicao requisicoes={this.state.requisicoes} />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}></GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default CompReqAbast;
