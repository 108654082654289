import React, { Component } from "react";
import auth from "services/auth";
import GridContainer from "components//Grid/GridContainer.js";
import GridItem from "components//Grid/GridItem.js";
//import Card from "../Card/Card.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import checatoken from "./checatoken";
import CardBody from "components/Card/CardBody.js";
import NavPills from "components/NavPills/NavPills.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Assignment from "@material-ui/icons/Assignment";
import TabelaMotorista from "./TabelaMotorista";
import Primary from "components/Typography/Primary.js";
import Muted from "components/Typography/Muted";
import Button from "components/CustomButtons/Button.js";
import classNames from "classnames";

const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const flexContainerClasses = classNames({
  [classNames.flexContainer]: true,
  [classNames.horizontalDisplay]: true,
});

class Motorista extends Component {
  state = {
    valorid: "",
    motorista: [],
  };

  
  async componentDidMount() {
    checatoken();
    await this.getDadosMotorista();
  }
  
  async getDadosMotorista() {
    const chave = localStorage.getItem("lukos-token-fw");
    const response = await auth.get("/faturaweb/GetTabelaMotorista", {
      headers: {
        token: chave,
      },
    });
    this.setState({ motorista: response.data });
  }

  novaMotorista() {
    console.log("NovaMotorista ");
    window.location.href = `/admin/editarmotorista/0`;
  }

  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Assignment />
                </CardIcon>
                <Muted>
                  <h4>Cadastro de Motoristas</h4>
                </Muted>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={6} sm={8} md={9}>
                      </GridItem>
                      <GridItem xs={6} sm={4} md={3}>
                        <Button  color="rose" className="marginRigth" style={{ width: "100%" }} onClick={() => this.novaMotorista()}>
                              Novo Motorista
                        </Button>
                      </GridItem>
                    </GridContainer>
                </GridItem>
              </CardHeader>
              <CardBody>
                <TabelaMotorista motoristas={this.state.motorista} />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}></GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default Motorista;
