import React, { Component } from "react";
import ReactDOM from "react-dom";
// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import auth from "services/auth";
import PropTypes from "prop-types";
import _ from "lodash";

// core components
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
// reactstrap components
import {
  Input
} from "reactstrap";

class TabelaVisualizaFatura extends Component {
    constructor(props){
       super(props);
       this.state = { fatura: [] }
    }

    state = {
       color: 0,
    }

    async componentDidMount() {
    
    }

    render(){
        return (
                <div>
                    teste
                    {/* <ReactTable
                        data={this.props.faturas.map((prop, key) => {
                            return{
                                id: "01",

                            }
                        })
                    }

                    />               */}
                </div>
            );
    }

    
}

export default TabelaVisualizaFatura;