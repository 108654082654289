import auth from 'services/auth';
import history from '../history';




export default function getchecaToken() {
        const chave = localStorage.getItem('lukos-token-fw');

        auth.get('/faturaweb/validatoken', {
            headers: {
                token: chave
            }
        })
        .then(res => {
            localStorage.setItem('lukos-token-fw', res.data.token);
        })
        .catch(error => {
            localStorage.removeItem('lukos-token-fw');
            history.push('/login');
        })                  


        const isLogged = !!localStorage.getItem('lukos-token-fw');

        if (isLogged === false) {
          history.push('/login');
        } 

        return (
            ''
        )

}


