import React, { Component } from "react";
import auth from "services/auth";
import PropTypes from "prop-types";
import ReactTable from "components/ReactTable/ReactTable.js";
import MediaQuery from 'react-responsive'



class TabelaCupons extends Component {
  constructor(props) {
    super(props);
    this.state = { frota: [] };

  }


  state = {
    color: 0,

  }


  async getDadosFrota() {
    const chave = localStorage.getItem('lukos-token-fw');
    const response = await auth.get('/faturaweb/gettabelafrota', {
      headers: {
        token: chave,
        color: this.state.color
      }
    });
    this.setState({ frota: response.data });
  }

  async componentDidMount() {
    //   await this.getDadosFrota();
    console.log("Tabela Frota");
    console.log("Tabela Frota" + this.state.frota);
  }

  render() {

   return (<div>
      <MediaQuery minWidth={1224}>
        {/* // <p>Desktop</p> */}
        <ReactTable
          data={this.props.cupons.map((prop, key) => {
            return {
              id: key,
              idCupom: prop[0],
              COO: <div className="actions-center">
                {prop[1]}
              </div>,
              // DataCupom: <MediaQuery minWidth={1224}>{prop[2]}</MediaQuery>,
              DataCupom: prop[2],
              ValorAFaturar: prop[3],
              Placa: prop[4],
              Motorista: prop[5],
              Hodometro: prop[6],
              Motorista: prop[7],
              Documento: prop[8],
            };
          })}


          columns={[
            { accessor: "COO", Header: "Nº Cupom", },
            { Header: "Data Cupom", accessor: "DataCupom", },
            { Header: "Placa", accessor: "Placa", },
            { Header: "Motorista", accessor: "Motorista", align: "center", },
            { Header: "KM", accessor: "Hodometro", },
            { Header: "A Faturar", accessor: "ValorAFaturar", },
            { Header: ".", accessor: "", },
          ]}
        />
      </MediaQuery>
      <MediaQuery maxWidth={1223}>
        {/* <p>Mobile</p> */}
        <ReactTable
          data={this.props.cupons.map((prop, key) => {
            return {
              id: key,
              idCupom: prop[0],
              COO: <div className="actions-center">
                {prop[1]}
              </div>,
              // DataCupom: <MediaQuery minWidth={1224}>{prop[2]}</MediaQuery>,
              DataCupom: prop[2],
              ValorAFaturar: prop[3],
              Placa: prop[4],
              Motorista: prop[5],
              Hodometro: prop[6],
              Motorista: prop[7],
              Documento: prop[8],
            };
          })}


          columns={[
            { accessor: "COO", Header: "Nº Cupom", },
            { Header: "Data Cupom", accessor: "DataCupom", },
            { Header: "Placa", accessor: "Placa", },
            { Header: "Motorista", accessor: "Motorista", align: "center", },
            { Header: "KM", accessor: "Hodometro", },
            { Header: "A Faturar", accessor: "ValorAFaturar", },
            { Header: ".", accessor: "", },
          ]}
        />
      </MediaQuery>

      {/* <MediaQuery minDeviceWidth={1224} maxDeviceWidth={1920}>
  <p>Mobile</p>
  </MediaQuery> */}

    </div>
    );
  }
}

TabelaCupons.propTypes = {
  cupons: PropTypes.array
}

export default TabelaCupons;
