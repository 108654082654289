import React, { Component } from "react";
import ReactDOM from "react-dom";
// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import auth from "services/auth";
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
// reactstrap components
import {
  Input
} from "reactstrap";


class TabelaEmpresa  extends Component  {
  constructor(props) {
    super(props);
    this.state = { frota: [] };
  }

  state = {
      color:0,
  }


   async componentDidMount() {
    
  }

  render() {
  return (<div>
    <ReactTable
      data={this.props.empresas.map((prop, key) => {
          return {
            id: key,
            Codigo: prop[0],
            Empresa: prop[1], 
            actions: (
              <div className="actions-right">
                <Button
                  justIcon
                  round
                  simple
                  onClick={() =>
                    window.location.href = '/admin/editarmotorista/'+prop[0]
                  }
                  color="warning"
                  className="edit"
                >
                  <Dvr />
                </Button>{" "}
              </div>
            )
          };
        })}
        columns={[
          // {
          //   Header: "Ativo",
          //   accessor: "Ativo",
          //   type:"checkbox"
          // },
         {
            Header: "Código",
            accessor: "Codigo"
          },
          {
            Header: "Empresa",
            accessor: "Empresa"
          }
           ,{
             Header: "",
             accessor: "Senha",
             type:"password"
           }
          // {
          //   Header: "Editar",
          //   accessor: "actions",
          //   sortable: false,
          //   filterable: false
          // }
        ]}
      />
      </div>
  );
}
}

TabelaEmpresa.propTypes = {
  empresas: PropTypes.array}

export default TabelaEmpresa;
