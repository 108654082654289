/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import EditarPlaca from "components/editarplaca.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

import history from './history.js';

const isLogged = !!localStorage.getItem('lukos-token-fw');

if (isLogged === false) {
  history.push('/login');
} 

ReactDOM.render(
  <Router history={history}>
    <Switch>

      <Route path="/rtl" component={RtlLayout} />
      <Route path="/auth" component={AuthLayout} />
      <Route path="/admin" component={AdminLayout} />
      <Redirect path="/login" to="auth/login-page" />
      <Redirect from="/" to="/admin/dashboard" />
      <Redirect path="/editaplaca" to="/admin/EditarPlaca" />
      <Redirect path="/visualizarfatura" to="/admin/VisualizarFatura" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
