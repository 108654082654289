import React, { Component, useState } from "react";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Contacts from "@material-ui/icons/Contacts";
import DateRange from "@material-ui/icons/DateRange";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Label, Row, Col, Input, FormGroup } from "reactstrap";
import Radio from "@material-ui/core/Radio";
import Swal from 'sweetalert2';
import auth from "services/auth";
import checatoken from './checatoken';
import NavPills from "./NavPills/NavPills.js";

import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { RepeatOneSharp } from "@material-ui/icons";
import TabelaVisualizaFatura from "./TabelaVisualizaFatura.js";
import TabelaCupons from "./TabelaCupons";
import TabelaItens from "./TabelaItens";
import TabelaServicos from "./TabelaServicos"
import axios from 'axios';
import { Servidor } from "services/auth";
import Datetime from "react-datetime";
import TagsInput from "react-tagsinput";
import moment from "moment";
import QRCode from 'qrcode.react';


export const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);


function downloadFile(url, NomeArquivo) {
  axios({
    url: url,
    method: 'GET',
    responseType: 'blob',
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', NomeArquivo);
    document.body.appendChild(link);
    link.click();
  });
}

class VisualizarRequisicao extends Component {
  constructor(props) {
    super(props);

    const hoje = new Date();
    hoje.setDate(hoje.getDate() + 1);


    this.state = {
      TabelaCupom: [],
      TabelaItens: [],
      TabelaServicos: [],
      requisicao: [],
      idRequisicao: 0,
      //validoAte: new Date('1982-04-29'),
      validoAte: hoje.toISOString().substr(0, 10),
      acumularsaldo: "",
      celular: "",
      placa: "",
      senha: "",
      valorLiberado: "0",
      chave: "",
      TipoTela: "I"
    };
    this.SalvaRequisicao = this.SalvaRequisicao.bind(this);
    this.MandaWhatsRequisicao = this.MandaWhatsRequisicao.bind(this);
    this.Salvaacumularsaldo = this.Salvaacumularsaldo.bind(this);
    this.recarrega = this.recarrega.bind(this);
    this.DetalheRequisicao = this.DetalheRequisicao.bind(this);
  }

  async componentDidMount() {
    console.log("visualizar Requisição")
    checatoken();
    const { id } = this.props.match.params;
    if (typeof id === "undefined") {
      console.log("NOVO" + id);
      this.setState({TipoTela : "I"});
    } else {
      console.log("Editar " + id);
      this.setState({TipoTela : "E"});
    }

    this.setState({ idRequisicao: id });
    await this.DetalheRequisicao();
  }

//   async DetalheRequisicao() {
//     const { id } = this.props.match.params;
//     console.log('aqui ok '+id);
//     const chave = localStorage.getItem("lukos-token-fw");
//    auth.get("/faturaweb/GetRequisicaoID4", {
//        headers: {
//          token: chave,
//          idRequisicao: id
//        },
//      }).then(response => {
//       this.setState({ requisicao: response.data })
//       this.setState({ acumularsaldo: response.data.acumularsaldo });
//       this.setState({ celular: response.data.celular });
//       this.setState({ placa: response.data.placa });
//       this.setState({ senha: response.data.senha });
//       this.setState({ validoAte: moment(response.data.validoAte, 'DD/MM/YYYY').format('YYYY-MM-DD') });
//       this.setState({ valorLiberado: numberFormat(response.data.valorLiberado) });
//       this.setState({ chave: response.data.chave });
//       //this.setState({ idRequisicao: response.data.idRequisicao });
//       console.log(response.data);
//      })
//        .catch(error => {
//          console.log(error);
//        })
//  }

async DetalheRequisicao() {
  console.log('Detalhes requisicao')
  const { id } = this.props.match.params;
  console.log('aqui ok '+id);
  const chave = localStorage.getItem("lukos-token-fw");
  try {
    const response = await auth.get("/faturaweb/GetRequisicaoID", {
      headers: {
        token: chave,
        idRequisicao: id,
        'Cache-Control': 'no-cache'
      },
    });
    this.setState({ requisicao: response.data });
    this.setState({ acumularsaldo: response.data.acumularsaldo });
    this.setState({ celular: response.data.celular });
    this.setState({ placa: response.data.placa });
    this.setState({ senha: response.data.senha });
    this.setState({ validoAte: moment(response.data.validoAte, 'DD/MM/YYYY').format('YYYY-MM-DD') });
    this.setState({ valorLiberado: numberFormat(response.data.valorLiberado) });
    this.setState({ chave: response.data.chave });
    console.log(response.data);
  } catch (error) {
    console.log(error);
  }
}


  async recarrega(){
    console.log("Cancelar Requisição")
    const { id } = this.props.match.params;
    this.setState({ idRequisicao: window.location.href.split("/")[5] });
    this.DetalheRequisicao();
  }

  
  async MandaWhatsRequisicao() {
    this.setState({
      celular: this.state.celular,
      acumularsaldo: this.state.acumularsaldo,
      placa: this.state.placa,
      senha: this.state.senha,
      validoAte: this.state.validoAte,
      valorLiberado: this.state.valorLiberado,
    }, () => {
      //const novoValor = this.state.celular;
      this.setState(prevState => ({
        requisicao: {
          ...prevState.requisicao,
          celular: this.state.celular,
          placa: this.state.placa,
          senha: this.state.senha,
          validoAte: moment(this.state.validoAte, 'YYYY-MM-DD').format('DD/MM/YYYY') ,
          valorLiberado:  parseFloat(this.state.valorLiberado.replace("R$", "").replace(",", ".").trim()).toFixed(2),
          acumularsaldo: this.state.acumularsaldo,
        }
      }), () => {

    // Enviando a bagaça.
          const chave = localStorage.getItem("lukos-token-fw");
           auth.get("/faturaweb/MandaWhatsRequisicao", {
            headers: {
              token: chave,
              Requisicao: JSON.stringify(this.state.requisicao)
            },
          }).then(response => {
            Swal.fire('Sucesso', 'A mensagem foi enviada!', 'success');
          })
            .catch(error => {
              console.log(error);
              Swal.fire('', error.response.data, 'error')
            })
        console.log(this.state.requisicao);
      });
    });
  }

 async SalvaRequisicao() {
    this.setState({
      celular: this.state.celular,
      acumularsaldo: this.state.acumularsaldo,
      placa: this.state.placa,
      senha: this.state.senha,
      validoAte: this.state.validoAte,
      valorLiberado: this.state.valorLiberado,
    }, () => {
      //const novoValor = this.state.celular;
      this.setState(prevState => ({
        requisicao: {
          ...prevState.requisicao,
          celular: this.state.celular,
          placa: this.state.placa,
          senha: this.state.senha,
          validoAte: moment(this.state.validoAte, 'YYYY-MM-DD').format('DD/MM/YYYY') ,
          valorLiberado:  parseFloat(this.state.valorLiberado.replace("R$", "").replace(",", ".").trim()).toFixed(2),
          acumularsaldo: this.state.acumularsaldo,
        }
      }), () => {

    // Enviando a bagaça.
          const chave = localStorage.getItem("lukos-token-fw");
           auth.get("/faturaweb/SalvaRequisicao", {
            headers: {
              token: chave,
              Requisicao: JSON.stringify(this.state.requisicao),
              tipoTela: 'I'
            },
          }).then(response => {
          //   console.log(response.data);
              this.setState({idRequisicao : response.data});
            
          //   Swal.fire('Sucesso', 'Dados foram salvos!', 'success');
          // })
          //   .catch(error => {
          //     console.log(error);
          //     Swal.fire('', error.response.data, 'error')
          //   })
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-center',
            timer: 3000,
            timerProgressBar: true
          })
          Toast.fire('Sucesso', 'Dados foram salvos!', 'success').then((result) => {
                                                                window.location.href = '/admin/visualizarequisicao/'+this.state.idRequisicao;
                                                                this.DetalheRequisicao();

                                                             })
          })
          .catch(error => {
            console.log(error);
            Swal.fire('OPS!', error.response.data, 'error')
          })          
      });
    });


  }

  async voltar(){
    console.log('Voltar');
    window.location.href = '/admin/RequisicaoAbastecimento';
  }

  SalvaCelular(event) {
    this.setState({ celular: event.target.value.toUpperCase() });
  }

  Salvaacumularsaldo(event) {
    console.log('Muda acumula:' + event.target.checked) 
    if (this.state.acumularsaldo === true) {
      this.setState({ acumularsaldo: false })
    } else {
      this.setState({  acumularsaldo: true })
    }

  }

 
  Salvaplaca(event) {
    this.setState({ placa: event.target.value.toUpperCase() });
  }

  Salvasenha(event) {
    this.setState({ senha: event.target.value.toUpperCase() });
  }

  SalvavalidoAte(event) {
    this.setState({ validoAte: event.target.value });
  }

  SalvavalorLiberado(event) {
    this.setState({ valorLiberado: event.target.value});
  }




  render() {
    return (
      <div>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Contacts />
                </CardIcon>
                <h3 style={{ color: "black" }}>Requisição de Abastecimento</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={6} sm={3} md={3}>
                        <CustomInput
                          // success={this.state.vPlaca}
                          //error={minLengthState === "error"}
                          id="idRequisicao"
                          name="idRequisicao"
                          labelText="Requisição Nº"
                          style={{ width: "100%" }}
                          enabled={false}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.idRequisicao,
                            type: "text",
                          }}
                        />
                      </GridItem>
                      <GridItem xs={6} sm={3} md={3}>
                        <CustomInput
                          id="celular"
                          name="celular"
                          labelText="Celular"
                          style={{ width: "100%" }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              this.SalvaCelular(event);
                            },
                            value: this.state.celular,
                            type: "text",
                          }}
                        />
                      </GridItem>
                      <GridItem xs={6} sm={3} md={3}>
                        <CustomInput
                          id="placa"
                          name="placa"
                          labelText="placa"
                          style={{ width: "100%" }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              this.Salvaplaca(event);
                            },
                            value: this.state.placa,
                            type: "text",
                          }}
                        />
                      </GridItem>
                      <GridItem xs={6} sm={3} md={3}>
                        <CustomInput
                          id="senha"
                          name="senha"
                          labelText="Senha"
                          style={{ width: "100%" }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              this.Salvasenha(event);
                            },
                            value: this.state.senha,
                            type: "text",
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={3} md={3}>
                        <FormControl fullWidth>
                          <CustomInput
                            id="validoAte"
                            name="validoAte"
                            labelText="Valido Até"
                            style={{ width: "100%" }}
                            dateFormat="DD/MM/yyyy"
                            locale="pt-br"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              onChange: (event) => {
                                this.SalvavalidoAte(event);
                              },
                              value: this.state.validoAte,
                              type: "date",
                            }}
                          />
                        </FormControl>
                        {/* <Datetime
                        id="validoAte"
                        name="validoAte"
                        closeOnSelect="true"
                        locale="pt-br"
                        onChange={this.SalvavalidoAte}
                        timeFormat={false}
                        inputProps={{ placeholder: "Valido Até" }}
                      />                         */}
                      </GridItem>
                      <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                          id="valorLiberado"
                          name="valorLiberado"
                          labelText="R$ Liberado"
                          style={{ width: "100%" }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              this.SalvavalorLiberado(event);
                            },
                            value: this.state.valorLiberado,
                            type: "numeric",
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          id="chave"
                          name="chave"
                          labelText="chave"
                          style={{ width: "100%" }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.chave,
                            type: "text",
                          }}
                        />
                        <div>
                          <h2>QR Code da chave:</h2>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <QRCode value={this.state.chave} />
                          </div>

                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormGroup check className="form-check-radio">
                      <Label check>
                        {/* <Input
                          id="Concluido"
                          name="Concluido"
                          type="checkbox"
                          checked={this.state.acumularsaldo === "True"}
                          onChange={this.SalvaStatus}
                          value={"True"}
                          inputProps={{
                            onChange: (event) => {
                              this.Salvaacumularsaldo(event);
                            },
                          }}
                        /> */}
                          <Input
                            id="Concluido"
                            name="Concluido" 
                            type="checkbox"
                            checked={this.state.acumularsaldo === true}
                            onChange={this.Salvaacumularsaldo}
                            value={true}
                          />                        
                        <span className="form-check-sign" />
                        Acumular Saldo {this.state.acumularsaldo}
                      </Label>
                    </FormGroup>
                  </GridItem>
                </Row>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={8} md={8} >
                      <Button color="success" className="marginLeft" onClick={this.SalvaRequisicao}>
                        Salvar
                      </Button>
                      <Button color="danger" className="marginLeft" onClick={this.recarrega}>
                        Cancelar
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={2} md={2}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button color="info" className="marginLeft" onClick={this.MandaWhatsRequisicao}>
                          Enviar no whatsapp
                        </Button>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button color="primary" className="marginLeft" onClick={this.voltar}>
                        Voltar
                      </Button>

                    </GridItem>
                  </GridContainer>
                </GridItem>
                <div>
                  {this.state.TipoTela === 'I' ? (
                    <span>INSERIR</span>
                  ) : (
                    <span>EDITAR</span>
                  )}
                </div>
              </CardBody>

            </Card>
          </GridItem>

        </GridContainer>
      </div>
    );
  }
}

export default VisualizarRequisicao;
