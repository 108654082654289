import Buttons from "views/Components/Buttons.js";
import Calendar from "views/Calendar/Calendar.js";
import Charts from "views/Charts/Charts.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import ContaCorrente from "views/Dashboard/contacorrente.js";
import DashboardOriginal from "views/Dashboard/DashboardOriginal";
import ErrorPage from "views/Pages/ErrorPage.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import GridSystem from "views/Components/GridSystem.js";
import Icons from "views/Components/Icons.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import Notifications from "views/Components/Notifications.js";
import Panels from "views/Components/Panels.js";
import PricingPage from "views/Pages/PricingPage.js";
import RTLSupport from "views/Pages/RTLSupport.js";
import ReactTables from "views/Tables/ReactTables.js";
import RegisterPage from "views/Pages/Registro.js";
import RegularForms from "views/Forms/RegularForms.js";
import RegularTables from "views/Tables/RegularTables.js";
import SweetAlert from "views/Components/SweetAlert.js";
import TimelinePage from "views/Pages/Timeline.js";
import Typography from "views/Components/Typography.js";
import UserProfile from "views/Pages/UserProfile.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import VectorMap from "views/Maps/VectorMap.js";
import Widgets from "views/Widgets/Widgets.js";
import Wizard from "views/Forms/Wizard.js";
import Frota from "./components/Frota"
import EditarPlaca from "components/editarplaca.js";
import Motorista from "./components/Motorista";
import EditarMotorista from "components/editarMotorista";
import Empresa from "components/Empresa";
import VisualizarFatura from "components/VisualizarFatura";
import Faturas from "components/Faturas";
import RequisicaoAbast from "views/Dashboard/RequisicaoAbastecimento"
import VisualizarRequisicao from "components/VisualizarRequisicao";
import ExtratoConsumo from "components/ExtratoConsumo"

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
import Place from "@material-ui/icons/Place";
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";
import recuperaSenha from "components/RecuperaSenha"


var dashRoutes =  [
  {
    path: "/dashboard",
    name: "Em Aberto",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin"
  },
  {
   collapse: true,
   name: "Cadastros",
   rtlName: "صفحات",
   icon: Image,
   state: "pageCollapse",
   views: [
    {
        path: "/Empresa",
        name: "Empresa",
        rtlName: "عالتسعير",
        mini: "",
        rtlMini: "ع",
        component: Empresa,
        layout: "/admin"
      },
      {
        path: "/Frota",
        name: "Frota",
        rtlName: "عالتسعير",
        mini: "",
        rtlMini: "ع",
        component: Frota,
        layout: "/admin"
      },
      {
        path: "/Motorista",
        name: "Motorista",
        rtlName: "عالتسعير",
        mini: "",
        rtlMini: "ع",
        component: Motorista,
        visible: false,
        layout: "/admin"
      },
      {
        path: "/recuperaSenha",
        name: "Trocar senha",
        rtlName: "عالتسعير",
        mini: "",
        rtlMini: "ع",
        component: recuperaSenha,
        visible: false,
        layout: "/admin"
      }

    ]
  },  
  {
    collapse: true,
    name: "Financeiro",
    rtlName: "صفحات",
    icon: Apps,
    state: "FinanceiroCollapse",
    views: [
      {
        path: "/Faturas",
        name: "Faturas",
        rtlName: "عالتسعير",
        mini: "PP",
        rtlMini: "ع",
        component: Faturas,
        layout: "/admin"
      },
      {
        path: "/RequisicaoAbastecimento",
        name: "Requisição Abastecimento",
        rtlName: "عالتسعير",
        mini: "ra",
        rtlMini: "ع",
        component: RequisicaoAbast,
        layout: "/admin"
      } ,
      {
        path: "/ContaCorrente",
        name: "Conta Corrente",
        rtlName: "عالتسعير",
        mini: "cc",
        rtlMini: "ع",
        component: ContaCorrente,
        layout: "/admin"
      } 
    ]
  },
  {
    collapse: true,
    name: "Relatórios",
    rtlName: "صفحات",
    icon: Timeline,
    state: "RelatorioCollapse",
    views: [{
              path: "/extratoconsumo",
              name: "Extrato Consumo",
              rtlName: "عالتسعير",
              mini: "",
              rtlMini: "ع",
              component: ExtratoConsumo,
              layout: "/admin",
              visible: false
            } 
    ]
  },
  {      
    path: "/editarplaca/:id",
    name: "",
    rtlName: "عالتسعير",
    mini: "",
    rtlMini: "ع",
    component: EditarPlaca,
    layout: "/admin",
    visible: false
  },
  {      
    path: "/visualizarequisicao/:id",
    name: "",
    rtlName: "عالتسعير",
    mini: "",
    rtlMini: "ع",
    component: VisualizarRequisicao,
    layout: "/admin",
    visible: false
  },
  {
    path: "/editarmotorista/:id",
    name: "",
    rtlName: "عالتسعير",
    mini: "",
    rtlMini: "ع",
    component: EditarMotorista,
    layout: "/admin",
    visible: false
  },
  {
    path: "/login-page",
    name: "",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth",
    icon: ""
  },
  {
    path: "/register-page",
    // name: "",
    // rtlName: "تسجيل",
    // mini: "R",
    // rtlMini: "صع",
    component: RegisterPage,
    layout: "/auth",
    // icon: ""
  },  
  {
    path: "/editarplaca/:id",
    name: "",
    rtlName: "عالتسعير",
    mini: "",
    rtlMini: "ع",
    component: EditarPlaca,
    layout: "/admin",
    visible: false
  },
  {
    path: "/editarmotorista/:id",
    name: "",
    rtlName: "عالتسعير",
    mini: "",
    rtlMini: "ع",
    component: EditarMotorista,
    layout: "/admin",
    visible: false
  },      
      {
        path: "/visualizarfatura/:id",
        name: "",
        rtlName: "عالتسعير",
        mini: "",
        rtlMini: "ع",
        component: VisualizarFatura,
        layout: "/admin",
        visible: false
      }  



];
export default dashRoutes;
