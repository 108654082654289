import React from "react";
import CompContaCorrente from "./../../components/compContaCorrente";
//import DashboardOriginal from "./DashboardOriginal";
import { Redirect } from "react-router-dom";

export default function ContaCorrente() {
  const isLogged = !!localStorage.getItem('lukos-token-fw');

  if (isLogged === false) {
    return (<Redirect to="/login" />)
  }
  else {
    return (
      <div>
        {/* <DashboardOriginal /> */}
        <CompContaCorrente />
      </div>
    );
  };
}

//original salvo como copia
