import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "components/ReactTable/ReactTable.js";
import Button from "components/CustomButtons/Button.js";
import Dvr from "@material-ui/icons/Dvr";
import {
  Input
} from "reactstrap";


class TabelaMotorista  extends Component  {
  constructor(props) {
    super(props);
    this.state = { frota: [] };
  }

  state = {
      color:0,
  }

  render() {
  return (<div>
    <ReactTable
      data={this.props.motoristas.map((prop, key) => {
          return {
            id: key,
            Documento: prop[1],
            Motorista: prop[2],
            Ativo: (
              <div className="actions-center">
                 <Input type="checkbox" 
                            name="vFilhos"
                            id="vFilhos" 
                            checked =  {prop[4] === 'True'}
                            readOnly
                          />       
               
                 </div>
                ),
            actions: (
              <div className="actions-right">
                <Button
                  justIcon
                  round
                  simple
                  onClick={() =>
                    window.location.href = '/admin/editarmotorista/'+prop[0]
                  }
                  color="warning"
                  className="edit"
                >
                  <Dvr />
                </Button>{" "}
              </div>
            )
          };
        })}
        columns={[{Header: "Ativo", accessor: "Ativo", type:"checkbox"},
                  {Header: "Documento", accessor: "Documento",  align: "center",  minWidth: "10%"},
                  {Header: "Motorista", accessor: "Motorista"},
                  {Header: "Editar", accessor: "actions", sortable: false, filterable: false}
                  ]}
      />
      </div>
  );
}
}

TabelaMotorista.propTypes = {
  motoristas: PropTypes.array}

export default TabelaMotorista;
