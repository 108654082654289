import React, { Component } from "react";
// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import PropTypes from "prop-types";
import _ from "lodash";

// core components
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import MediaQuery from 'react-responsive'



class TabelaFaturas extends Component {
  constructor(props) {
    super(props);
    this.state = { frota: [] };
  }

  state = {
    color: 0,
  }


  async componentDidMount() {

  }

  render() {
    return (<div>
      <MediaQuery minWidth={1224}>
        {/* // <p>Desktop</p> */}

        <ReactTable
          data={this.props.faturas.map((prop, key) => {
            return {
              id: key,
              idFatura: prop[0],
              DataAbertura: prop[1],
              DataFechamento: prop[2],
              ValorTotal: prop[3],
              ValorPago: prop[4],
              ValorEmAberto: prop[5],
              DataVencimento: prop[6],
              actions: (
                <div className="actions-right" style={{ width: "100px"}} >
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() =>
                      window.location.href = '/admin/visualizarfatura/' + prop[0]
                    }
                    color="warning"
                    className="edit"
                    
                  >
                    <Dvr />
                  </Button>{" "}
                </div>
              )
            };
          })}
          columns={[{ Header: "Nº Fatura", accessor: "idFatura", type: "checkbox" },
          { Header: "Abertura", accessor: "DataAbertura" },
          { Header: "Fechamento", accessor: "DataFechamento" },
          { Header: "Valor Total", accessor: "ValorTotal", },
          { Header: "Valor Pago", accessor: "ValorPago", },
          { Header: "Em Aberto", accessor: "ValorEmAberto", },
          { Header: "Vencimento", accessor: "DataVencimento", },
          { Header: "Editar", accessor: "actions", sortable: false, filterable: false}
          ]}
        />
      </MediaQuery>
      <MediaQuery maxWidth={1223}>
        <p>Mobile</p>
        <ReactTable
          data={this.props.faturas.map((prop, key) => {
            return {
              id: key,
              idFatura: prop[0],
              DataAbertura: prop[1],
              DataFechamento: prop[2],
              ValorTotal: prop[3],
              ValorPago: prop[4],
              ValorEmAberto: prop[5],
              DataVencimento: prop[6],
              actions: (
                <div className="actions-right" >
                  <Button 
                    justIcon
                    round
                    simple
                    onClick={() =>
                      window.location.href = '/admin/visualizarfatura/' + prop[0]
                    }
                    color="primary"
                    className="edit"
                  >
                    <Dvr />
                  </Button>{" "}
                </div>
              )
            };
          })}
          columns={[{ Header: "Nº Fatura", accessor: "idFatura", type: "checkbox" },
          { Header: "Abertura", accessor: "DataAbertura" },
          { Header: "Fechamento", accessor: "DataFechamento" },
          { Header: "Valor Total", accessor: "ValorTotal", },
          { Header: "Valor Pago", accessor: "ValorPago", },
          { Header: "Em Aberto", accessor: "ValorEmAberto", },
          { Header: "Vencimento", accessor: "DataVencimento", },
          { Header: "Editar", accessor: "actions", sortable: false, filterable: false}
          ]}
        />
      </MediaQuery>

    </div>
    );
  }
}

TabelaFaturas.propTypes = {
  faturas: PropTypes.array
}

export default TabelaFaturas;
