import React, { Component } from "react";
import ReactDOM from "react-dom";
// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import auth from "services/auth";
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
// reactstrap components
import {
  Input
} from "reactstrap";





class TabelaFrota  extends Component  {
  constructor(props) {
    super(props);
    this.state = { frota: [] };
  }

  state = {
      color:0,
  }

async getDadosFrota() {
    const chave = localStorage.getItem('lukos-token-fw');
    const response = await auth.get('/faturaweb/gettabelafrota', {
    headers: {
        token: chave,
        color: this.state.color
    }
});
this.setState({ frota: response.data});
}

   async componentDidMount() {
  //   await this.getDadosFrota();
  console.log("Tabela Frota" );
  console.log("Tabela Frota" + this.state.frota);
    
  }

  render() {
  return (<div>
    {/* <h1>{this.props.frota}</h1> */}
    <ReactTable
      data={this.props.frota.map((prop, key) => {
          return {
            id: key,
            Codigo: prop[0],
            Placa: prop[1],
            Veiculo: prop[2],
            Ativo: (
              // we've added some custom button actions
              <div className="actions-center">
                 <Input type="checkbox" 
                            name="vFilhos"
                            id="vFilhos" 
                            //defaultValue = {prop[3] === 'true'}
                            checked =  {prop[3] === 'true'}
                            readOnly
                          />       
               
                 </div>
                ),
           // LimitePorFatura: prop[4],
            //EmAberto: prop[5],
           // LitrosPorFatura: prop[6],
          //  LitrosNaFatura: prop[7],
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                <Button
                  justIcon
                  round
                  simple
                  onClick={() =>
                    window.location.href = '/admin/editarplaca/centro/'+prop[0]
                    //alert("You've pressed the edit button on colmun id: " + prop[0])
                  }
                  color="warning"
                  className="edit"
                >
                  <Dvr />
                </Button>{" "}
              </div>
            )
          };
        })}
        columns={[
          // {
          //   Header: "Codigo",
          //   accessor: "Codigo"
          // },
          {
            Header: "Ativo",
            accessor: "Ativo",
            type:"checkbox"
          },
         {
            Header: "Placa",
            accessor: "Placa"
          },
          {
            Header: "Veiculo",
            accessor: "Veiculo"
          },

          // {
          //   Header: "Limite Por Fatura",
          //   accessor: "LimitePorFatura"
          // },
        //  {
          //   Header: "EmA berto",
          //   accessor: "EmAberto"
          // },
          // {
          //   Header: "Litros Por Fatura",
          //   accessor: "LitrosPorFatura"
          // },
          // {
          //   Header: "Litros Na Fatura",
          //   accessor: "LitrosNaFatura"
          // },

          {
            Header: "Editar",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      />

      </div>
  );
}
}

TabelaFrota.propTypes = {
  frota: PropTypes.array}

export default TabelaFrota;
