import React, { Component } from "react";
import auth from "services/auth";
import GridContainer from "components//Grid/GridContainer.js";
import GridItem from "components//Grid/GridItem.js";
//import Card from "../Card/Card.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import checatoken from "./checatoken";
import CardBody from "components/Card/CardBody.js";
import NavPills from "components/NavPills/NavPills.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Assignment from "@material-ui/icons/Assignment";
import TabelaFrota from "./TabelaFrota";
import Primary from "components/Typography/Primary.js";
import Button from "components/CustomButtons/Button.js";
import classNames from "classnames";
import EditarPlaca from "./editarplaca";
import { Redirect } from "react-router-dom";

const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const flexContainerClasses = classNames({
  [classNames.flexContainer]: true,
  [classNames.horizontalDisplay]: true,
});

class Frota extends Component {
  state = {
    enviaLista: [],
    valorid: "",
    CentroPadrao: "Centro Padrão",
    frota: [],
  };

  async getCentroCusto() {
    const chave = localStorage.getItem("lukos-token-fw");
    const response = await auth.get("/faturaweb/centrocusto", {
      headers: {
        token: chave,
      },
    });
    this.setState({ enviaLista: response.data.enviaLista });
  }

  async componentDidMount() {
    checatoken();
    await this.getCentroCusto();
    await this.getDadosFrota("0");
  }

  async ChecaLogin() {
    console.log("Evento");
  }

  async trocaCentroCusto(Index) {
    const chave = localStorage.getItem("lukos-token-fw");
    const response = await auth.get("/faturaweb/alteracentrocusto", {
      headers: {
        token: chave,
        centro: Index,
      },
    });
    localStorage.setItem("lukos-token-fw", response.data.token);
  }

  async getDadosFrota(index) {
    const chave = localStorage.getItem("lukos-token-fw");
    const response = await auth.get("/faturaweb/gettabelafrota", {
      headers: {
        token: chave,
        idCentro: index,
      },
    });
    this.setState({ frota: response.data });
  }

  handleClick(index) {
    console.log("Troca Centro " + index);
    this.trocaCentroCusto(index);
    this.setState({ CentroPadrao: "Centro" + index });
    this.getDadosFrota(index);
  }

  novaplaca(centro) {
    console.log("NovaPlaca " + centro);
    window.location.href = `/admin/editarplaca/${centro}`;
  }

  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <h4>
                  Centro de Custo <small> </small>
                </h4>
              </CardHeader>
              <CardBody>
                <NavPills
                  onClick={(event) => {
                    //console.log("Como?");
                    this.handleClick(event);
                  }}
                  active={0}
                  color="rose"
                  // horizontal={{
                  //     tabsGrid: { xs: 12, sm: 12, md: 4},
                  //     contentGrid: { xs: 12, sm: 12, md: 8 }
                  //   }}

                  tabs={this.state.enviaLista.map((ans) => ({
                    tabButton: ans.centroCusto,
                    tabContent: (
                      <span>
                        <p>Tipo Faturamento: {ans.tipoFaturamento}</p>
                        <br />
                        <p>Detalhe Faturamento: {ans.detalheFat}</p>
                        <Button
                          color="success"
                          round
                          className="marginRight"
                          onClick={() => this.novaplaca(ans.centroCusto)}
                        >
                          Adicionar Placa 
                        </Button>
                      </span>
                    ),

                    // (<p>ans.tipoFaturamento</p> + ' - '+ ans.detalheFat)
                  }))}
                />
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
                <Primary>
                  Cadastro de Veículos - {this.state.CentroPadrao}
                </Primary>
              </CardHeader>
              <CardBody>
                {/* <TabelaFrota  frota={[["30238", "BFG-2810", "IMP/PIERCE", "true", "R$ 0,00", "R$ 0,00", "0,00 lts", "0,00 lts"]]}/> */}
                <TabelaFrota frota={this.state.frota} />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}></GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default Frota;
