import React, { Component } from "react";
import auth from "services/auth";
import GridContainer from "components//Grid/GridContainer.js";
import GridItem from "components//Grid/GridItem.js";
//import Card from "../Card/Card.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import checatoken from "./checatoken";
import CardBody from "components/Card/CardBody.js";
import NavPills from "components/NavPills/NavPills.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Assignment from "@material-ui/icons/Assignment";
import TabelaEmpresa from "./TabelaEmpresas";
import Primary from "components/Typography/Primary.js";
import Muted from "components/Typography/Muted";
import Button from "components/CustomButtons/Button.js";
import classNames from "classnames";
import Icon from "@material-ui/core/Icon";
import CardFooter from "components/Card/CardFooter.js";
import DateRange from "@material-ui/icons/DateRange";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormLabel from "@material-ui/core/FormLabel";
import Swal from "sweetalert2";

const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const flexContainerClasses = classNames({
  [classNames.flexContainer]: true,
  [classNames.horizontalDisplay]: true,
});

class Empresa extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cnpjPosto: "",
      cnpjCliente: "",
      codigoLiberacao: "",
      vEmpresas: [],
    };

    this.SalvaCNPJPosto = this.SalvaCNPJPosto.bind(this);
    this.SalvaCNPJCliente = this.SalvaCNPJCliente.bind(this);
    this.SalvaCodigoLiberacao = this.SalvaCodigoLiberacao.bind(this);
  }

  SalvaCNPJPosto(event) {
    this.setState({ cnpjPosto: event.target.value.toUpperCase() });
  }

  SalvaCNPJCliente(event) {
    this.setState({ cnpjCliente: event.target.value.toUpperCase() });
  }

  SalvaCodigoLiberacao(event) {
    this.setState({ codigoLiberacao: event.target.value.toUpperCase() });
  }

  async componentDidMount() {
    checatoken();
    await this.getDadosEmpresa();
  }

  async getDadosEmpresa() {
    const chave = localStorage.getItem("lukos-token-fw");
    const response = await auth.get("/faturaweb/EmpresasCad/", {
      headers: {
        token: chave,
      },
    });
    this.setState({ vEmpresas: response.data });
  }

  async EnviaDadosEmpresaNova() {
    const chave = localStorage.getItem("lukos-token-fw");
    await auth
      .get("/faturaweb/VinculaUnidade", {
        headers: {
          token: chave,
          CNPJPosto: this.state.cnpjPosto,
          CNPJCliente: this.state.cnpjCliente,
          CodigoLiberacao: this.state.codigoLiberacao,
        },
      })
      .then((res) => {
        localStorage.setItem("lukos-token-fw", res.data.token);
        this.getDadosEmpresa();
        Swal.fire("Tudo certo", "", "success");
      })
      .catch((error) => {
        Swal.fire("Erro.", error.response.data, "error");
      });
  }

  opensweetalert() {
    this.EnviaDadosEmpresaNova();
  }

  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Assignment />
                </CardIcon>
                <Muted>
                  <h3>Empresas Cadastradas</h3>
                </Muted>
              </CardHeader>
              <CardBody>
                <TabelaEmpresa empresas={this.state.vEmpresas} />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning"  icon>
                <CardIcon color="warning">
                  <Icon>add_shopping_cart</Icon>
                </CardIcon>
                <Muted>
                  <h3>Novo Cadastro</h3>
                </Muted>
              </CardHeader>
              <GridContainer>
                <CardBody>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                        <CustomInput
                          //success={this.state.vPlaca}
                          //error={minLengthState === "error"}
                          id="edtCNPJPosto"
                          name="edtCNPJPosto"
                          labelText="CNPJ do POSTO"
                          style={{ width: "100%" }}
                          onChange={this.SalvaCNPJPosto}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              this.SalvaCNPJPosto(event);
                            },
                            value: this.state.cnpjPosto,
                            type: "number",
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <CustomInput
                          //success={this.state.vPlaca}
                          //error={minLengthState === "error"}
                          id="edtCNPJCliente"
                          name="edtCNPJCliente"
                          labelText="MEU CNPJ"
                          style={{ width: "100%" }}
                          onChange={this.SalvaCNPJCliente}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              this.SalvaCNPJCliente(event);
                            },
                            value: this.state.cnpjCliente,
                            type: "number",
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={4} md={4}>
                        <CustomInput
                          //success={this.state.edtNome}
                          //error={minLengthState === "error"}
                          id="edtCodigoLiberacao"
                          name="edtCodigoLiberacao"
                          labelText="Código de Liberação"
                          style={{ width: "100%" }}
                          onChange={this.SalvaCodigoLiberacao}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              this.SalvaCodigoLiberacao(event);
                            },
                            value: this.state.codigoLiberacao,
                            type: "number",
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </CardBody>
              </GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}></GridItem>
                  <GridItem xs={12} sm={12} md={4}></GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Button
                      color="success"
                      className="marginLeft"
                      onClick={() => this.opensweetalert()}
                    >
                      Adicionar
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>

              <CardFooter stats>
                <DateRange />
                Adicionar novo posto com sistema Lukos.
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default Empresa;
