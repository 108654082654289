import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import Logo from './retaguarda_64.png';
import auth from "./../../services/auth";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";
import history from "../../history";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Swal from "sweetalert2";


import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

export default function RegisterPage() {
  const [checked, setChecked] = React.useState([]);
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const [myUser, setUser] = useState();

  const handleInputChange = ev => {
    let name = [ev.target.id];
    let value = ev.target.value;
    setUser(
      { ...myUser, [name]: value }
    );
  }


  const ChacaLogin =  async  ev => {
    ev.preventDefault();
    try {


      const response  = await auth.post('/faturaweb/CriaUsuario', {
        params: {
          Usuario: myUser,
          host: window.location.href
        }
      }

      )
      console.log(response);
      switch (response.status) {
        case 200:
          Swal.fire('Concluído', 'Cadastro efetuado com sucesso.', 'success');
          history.push('/auth/login-page');
          break;
        case 201:
          console.log(response.data);
          Swal.fire('OPS', response.data, 'error');

          break;
        default:
          console.log(response);
          Swal.fire('OPS!', response.data, 'error');
      }
    } catch (error) {
      Swal.fire('', error.response.data, 'error');
    }
  }


  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <CardBody>
              <GridContainer justify="center">
                <img src={Logo} alt="logo" />
              </GridContainer>
              <GridContainer justify="center">
                <h1>Cadastro</h1>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={5}>
                  <CustomInput
                    id="CNPJPosto"
                    name="CNPJPosto"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      onChange: (ev) => handleInputChange(ev),
                      type: "number",
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Group className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      placeholder: "CNPJ  do Posto ..."
                    }}
                  />
                  <CustomInput
                    id="CNPJCliente"
                    name="CNPJCliente"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      onChange: (ev) => handleInputChange(ev),
                      type: "number",
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Face className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      placeholder: "MEU CNPJ..."
                    }}
                  />
                  <CustomInput
                    id="CodLiberacao"
                    name="CodLiberacao"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      onChange: (ev) => handleInputChange(ev),
                      type: "number",
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          <Code className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      placeholder: "Código Liberação"
                    }}
                  />

                </GridItem>
                <GridItem xs={12} sm={8} md={5}>

                  <form className={classes.form}>
                    <CustomInput
                      id="Email"
                      name="Email"

                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        onChange: (ev) => handleInputChange(ev),
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Email..."
                      }}
                    />
                    <CustomInput
                      id="Senha"
                      name="Senha"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        type: "password",
                        onChange: (ev) => handleInputChange(ev),
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        placeholder: "Senha..."
                      }}
                    />
                    <CustomInput
                      id="Confirma"
                      name="Confirma"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        type: "password",
                        onChange: (ev) => handleInputChange(ev),
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        placeholder: "Confirme a senha"
                      }}
                    />
                    {/* <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel
                      }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => handleToggle(1)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      label={
                        <span>
                          I agree to the{" "}
                          <a href="#pablo">terms and conditions</a>.
                        </span>
                      }
                    /> */}
                    <div className={classes.center}>
                      <GridContainer justify="center">
                        <Button round color="primary" onClick={ChacaLogin}>
                          Enviar Cadastro
                        </Button>
                      </GridContainer>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
