import React, { Component } from "react";
import ReactDOM from "react-dom";
// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import auth from "services/auth";
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
// reactstrap components
import {
  Input
} from "reactstrap";





class TabelaItens  extends Component  {
  constructor(props) {
    super(props);
    this.state = { frota: [] };
  }

  state = {
      color:0,
  }

async getDadosFrota() {
    const chave = localStorage.getItem('lukos-token-fw');
    const response = await auth.get('/faturaweb/gettabelafrota', {
    headers: {
        token: chave,
        color: this.state.color
    }
});
this.setState({ frota: response.data});
}

   async componentDidMount() {

    
  }

  render() {
  return (<div>
    {/* <h1>{this.props.frota}</h1> */}
    <ReactTable
      data={this.props.itens.map((prop, key) => {
          return {
            id: key,
            idCupom: prop[0],
            COO: prop[1],
            DataCupom: prop[2],
            Placa: prop[3],
            Produto: prop[5],
            qCom: prop[6],
            vUnitario: prop[7],
            Desconto: prop[8],
            TotalItem: prop[9]
          };
        })}
        columns={[
          {accessor: "COO", Header: "Nº Cupom"},
          {Header: "Data", accessor: "DataCupom" },
          {Header: "Placa",accessor: "Placa"},
          {Header: "Produto", accessor: "Produto"},
          {Header: "Qtde", accessor: "qCom"},
          {Header: "R$ Unit", accessor: "vUnitario"},
          {Header: "Desconto", accessor: "Desconto"},
          {Header: "R$ Total", accessor: "TotalItem"},
          { Header: "", accessor: "actions", sortable: false, filterable: false}     
        ]}
      />

      </div>
  );
}
}

TabelaItens.propTypes = {
  itens: PropTypes.array}

export default TabelaItens;
