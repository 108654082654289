import React, { Component } from "react";
// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import PropTypes from "prop-types";
import _ from "lodash";

// core components
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import MediaQuery from 'react-responsive'



class TabelaExtratoCupom extends Component {
  constructor(props) {
    super(props);
    this.state = { frota: [] };
  }

  state = {
    color: 0,
  }


  async componentDidMount() {

  }

  render() {
    return (<div>
      <MediaQuery minWidth={1224}>
        {/* // <p>Desktop</p> */}

        <ReactTable
          data={this.props.faturas.map((prop, key) => {
            return {
              id: key,
              idCupom: prop[0],
              DataCupom: prop[1],
              TotalCupom: prop[2],
              COO: prop[3],
              TotalFaturado: prop[4],
              Placa: prop[5],
              Motorista: prop[6],
              km: prop[7],
              CodFatura: prop[8],
              DataFechamento: prop[9],
              DataVencimento: prop[10],
              actions: (
                <div className="actions-right" style={{ width: "100px"}} >
                  {/* <Button
                    justIcon
                    round
                    simple
                    onClick={() =>
                      window.location.href = '/admin/visualizarfatura/' + prop[0]
                    }
                    color="warning"
                    className="edit"
                    
                  >
                    <Dvr />
                  </Button>{" "} */}
                </div>
              )
            };
          })}
          columns={[//{ Header: "Cód Cupom", accessor: "idCupom"},
          { Header: "Nº Fiscal", accessor: "COO", },
          { Header: "Data Venda", accessor: "DataCupom" },
          { Header: "$ Cupom", accessor: "TotalCupom" },
          { Header: "$ Faturado", accessor: "TotalFaturado", },
          { Header: "Placa", accessor: "Placa", },
          { Header: "Motorista", accessor: "Motorista", },
          { Header: "km", accessor: "km", },
          { Header: "Cód. Fatura", accessor: "CodFatura", },
          { Header: "Fechamento", accessor: "DataFechamento", },
          { Header: "Vencimento", accessor: "DataVencimento", },
          { Header: "", accessor: "actions", sortable: false, filterable: false}
          ]}
        />
      </MediaQuery>
      <MediaQuery maxWidth={1223}>
        <p>Mobile</p>
        <ReactTable
          data={this.props.faturas.map((prop, key) => {
            return {
              id: key,
              idCupom: prop[0],
              DataCupom: prop[1],
              TotalCupom: prop[2],
              COO: prop[3],
              TotalFaturado: prop[4],
              Placa: prop[5],
              Motorista: prop[6],
              km: prop[7],
              CodFatura: prop[8],
              DataFechamento: prop[9],
              DataVencimento: prop[10],
              actions: (
                <div className="actions-right" >
                  {/* <Button 
                    justIcon
                    round
                    simple
                    onClick={() =>
                      window.location.href = '/admin/visualizarfatura/' + prop[0]
                    }
                    color="primary"
                    className="edit"
                  >
                    <Dvr />
                  </Button>{" "} */}
                </div>
              )
            };
          })}
          columns={[
            { Header: "Nº Fiscal", accessor: "COO", },
            { Header: "Data Cupom", accessor: "DataCupom" },
//            { Header: "Total Cupom", accessor: "TotalCupom" },
            { Header: "Total Faturado", accessor: "TotalFaturado", },
            { Header: "Placa", accessor: "Placa", },
            { Header: "Motorista", accessor: "Motorista", },
            { Header: "km", accessor: "km", },
            { Header: "Cód. Fatura", accessor: "CodFatura", },
//            { Header: "Fechamento", accessor: "DataFechamento", },
            { Header: "Vencimento", accessor: "DataVencimento", },
            { Header: "", accessor: "actions", sortable: false, filterable: false}
          ]}
        />
      </MediaQuery>

    </div>
    );
  }
}

TabelaExtratoCupom.propTypes = {
  faturas: PropTypes.array
}

export default TabelaExtratoCupom;
