import React, { Component } from "react";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Contacts from "@material-ui/icons/Contacts";
import DateRange from "@material-ui/icons/DateRange";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Label, Row, Col, Input, FormGroup } from "reactstrap";
import Radio from "@material-ui/core/Radio";
import Swal from 'sweetalert2';
import auth from "services/auth";
import checatoken from './checatoken';

import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { ContactSupportOutlined, RepeatOneSharp } from "@material-ui/icons";

class EditarPlaca extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idPlaca: "",
      edtPlaca: "",
      idCentro: 0,
      edtCentro: "N",
      edtVeiculo: "",
      edtMotorista: "",
      edtSenha: "",
      edtLimiteR: 0,
      edtUsadoR: 0,
      edtLimiteDisp: 0,
      edtLitros: 0,
      edtLitrosUsados: 0,
      edtLitrosDisp: 0,
      edtAtivo: "",

      edtCentroCusto: [""],
    };

    this.handleChangeGenero = this.handleChangeGenero.bind(this);
    this.SalvaEditplaca = this.SalvaEditplaca.bind(this);
    this.SalvaEditVeiculo = this.SalvaEditVeiculo.bind(this);
    this.SalvaEditMotorista = this.SalvaEditMotorista.bind(this);
    this.SalvaEditSenha = this.SalvaEditSenha.bind(this);
    this.SalvaLimiteR = this.SalvaLimiteR.bind(this);
    this.SalvaLitros = this.SalvaLitros.bind(this);
    this.SalvaEditAtivo = this.SalvaEditAtivo.bind(this);
  }

  async componentDidMount() {
    checatoken();
    const { id } = this.props.match.params;
    if (typeof id === "undefined") {
      console.log("NOVO" + id);
    } else {
      console.log("Editar " + id);
    }

    this.setState({ idPlaca: window.location.href.split("/")[6] });
    //this.setState({ edtCentro: window.location.href.split("/")[5] });
    console.log(this.props.match.params.id);
    console.log(window.location.href.split("/")[4]);
    console.log(window.location.href.split("/")[6]);

    console.log("IdPlaca: " + this.state.idPlaca);
    await this.OpcaoCentro();
    await this.DadosPlaca();
  }

  async DadosPlaca(){
    const chave = localStorage.getItem("lukos-token-fw");
    await auth.get("/faturaweb/DadosPlaca", {
      headers: {
        token: chave,
        idPlaca: this.state.idPlaca
      }, }).then(response => {
        this.setState({idPlaca : response.data.idPlaca});
        this.setState({idCentro : response.data.idCentroCusto});
        this.setState({edtCentro : response.data.centroCusto});
        this.setState({edtPlaca : response.data.placa});
        this.setState({edtMotorista : response.data.motorista});
        this.setState({edtVeiculo : response.data.veiculo});
        this.setState({edtSenha : response.data.senha});
    
        this.setState({edtLimiteR : response.data.limiteValor});
        this.setState({edtUsadoR : response.data.limiteUsado});
        this.setState({edtLimiteDisp : response.data.limiteDisponivel});
        this.setState({edtLitros : response.data.limiteLitros});
        this.setState({edtLitrosUsados : response.data.litrosUsados});
        this.setState({edtLitrosDisp : response.data.litrosDisp});
        this.setState({edtAtivo: response.data.ativo})
        
        
        console.log(response.data); 
             })
    .catch(error => {
      console.log(error);
    })      
  }

  verifyLength(value, length) {
    if ((value.length = length)) {
      this.setState({ vPlaca: true });
    }
    this.setState({ vPlaca: false });
  }

  SalvaEditAtivo(event) {
    // if ((this.state.edtAtivo ===  true)) {
    //   console.log("Muda false");
    //   this.setState({ edtAtivo: false });
    // } else
    // {
    //   console.log("Muda True");
    // this.setState({ edtAtivo: true });
    // }
    console.log(event.target.value);
    this.setState({ edtAtivo: event.target.value});
  }


  SalvaEditplaca(event) {
    this.setState({ edtPlaca: event.target.value.toUpperCase() });
  }

  SalvaEditVeiculo(event) {
    this.setState({ edtVeiculo: event.target.value.toUpperCase() });
  }

  SalvaEditMotorista(event) {
    this.setState({ edtMotorista: event.target.value.toUpperCase() });
  }

  SalvaEditSenha(event) {
    this.setState({ edtSenha: event.target.value.toUpperCase() });
  }

  SalvaLimiteR(event) {
    this.setState({ edtLimiteR: event.target.value });
    this.setState({ edtLimiteDisp: event.target.value - this.state.edtUsadoR})
  }

  SalvaLitros(event){
    this.setState({ edtLitros: event.target.value});
    this.setState({ edtLitrosDisp: event.target.value - this.state.edtLitrosUsados});
  }

  handleChangeGenero(event) {
    console.log(event.target.value);
    this.setState({ edtCentro: event.target.value });
  }

  async EnviaDadosPlaca(){
    try {
    const chave = localStorage.getItem("lukos-token-fw");
    const res = await auth.get("/faturaweb/GetSalvaPlaca", {
      headers: {
                token: chave,
                idPlaca : this.state.idPlaca,
                idCentro : this.state.idCentro,
                Centro : this.state.edtCentro,
                Placa : this.state.edtPlaca,
                Motorista : this.state.edtMotorista,
                Veiculo : this.state.edtVeiculo,
                Senha : this.state.edtSenha,
                LimiteR : this.state.edtLimiteR,
                Litros : this.state.edtLitros,
                Ativo: this.state.edtAtivo
              },
       })
       console.log(res);
       switch (res.status) {
        case 200:
            console.log('200');
          Swal.fire({
            title: Sucesso,
            text: res.data,
            icon: "success"
          });
          break;
        case 201:
          Swal.fire({
            title: 'Erro',
            text: res.data,
            icon: "error"
          });
          showErroProd('OPS', res.data);
          break;
        default:
          Swal.close();
      }
       
       //.then(res => {

      //    const Toast = Swal.mixin({
      //      toast: true,
      //      position: 'top-center',
      //      timer: 3000,
      //      timerProgressBar: true
      //    })
      //    Toast.fire('Sucesso', res.data, 'success').then((result) => {
      //                                                         this.voltar();
      //                                                       })
      //   })
      //   .catch(error => {
      //     console.log(error);
      //     Swal.fire('Deu ruim!', error.response.data, 'error')
      //   })
    } catch (error) {
      showErroProd('Erro ao consultar produto.', error.message);
    } finally {
      // Swal.close() movido para aqui, para garantir que seja chamado após a execução de todos os casos
    }
    };


  teste(){
    this.EnviaDadosPlaca();
  }
  
  opensweetalert()
  {
  
      Swal.fire({
      title: 'Confirma as alterações?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: `Não`,
      confirmButtonColor: '#578F3D',
      cancelButtonColor: '#d33',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) { 
        this.teste();
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  async voltar(){
    console.log('Voltar');
    window.location.href = '/admin/Frota/';
    
  }

  async OpcaoCentro(){
    const chave = localStorage.getItem("lukos-token-fw");
    await auth.get("/faturaweb/opcaocentrocusto", {
      headers: {
        token: chave,
      },
        }).then(res => {
          console.log(res.data);
          this.setState({ edtCentroCusto : res.data})
       })
      .catch(error => {
        console.log(error);
        Swal.fire('Sem  Conexão, tente novamente em alguns minutos.', '', 'error');
      })      
  }



  render() {
    return (
      <div>
        <h1>
          {/* Editar Placa + {this.state.idPlaca} + {this.state.idCentroCusto}{" "} */}
        </h1>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Contacts />
                </CardIcon>
                <h3 style={{ color: "black" }}>Cadastro de Placa</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          success={this.state.vPlaca}
                          //error={minLengthState === "error"}
                          id="edtPlaca"
                          name="edtPlaca"
                          labelText="Placa"
                          style={{ width: "100%" }}
                          onChange={this.SalvaEditplaca}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              this.SalvaEditplaca(event);
                            },
                            value: this.state.edtPlaca,
                            type: "text",
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          //success={this.state.vPlaca}
                          //error={minLengthState === "error"}
                          id="edtSenha"
                          name="edtsenha"
                          labelText="Senha"
                          style={{ width: "100%" }}
                          onChange={this.edtSenha}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              this.SalvaEditSenha(event);
                            },
                            value: this.state.edtSenha,
                            type: "Password",
                            autoComplete: "off",
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <h6>Informe o centro de custo:</h6>
                        <Col className="checkbox-radios" sm="10">
                          {this.state.edtCentroCusto.map((ccc) => (
                            <FormGroup check className="form-check-radio">
                              <Label check>
                                <Input
                                  id="vCentro"
                                  name="vCentro"
                                  type="radio"
                                  checked={this.state.edtCentro === ccc}
                                  onChange={this.handleChangeGenero}
                                  value={ccc}
                                />
                                <span className="form-check-sign" />
                                {ccc}
                              </Label>
                            </FormGroup>
                          ))}
                        </Col>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                    <h6>Placa Ativa</h6>
                    <Col className="checkbox-radios" sm="10">
                            <FormGroup check className="form-check-radio">
                              <Label check>
                                <Input
                                  id="vAtivo"
                                  name="vAtivo"
                                  type="radio"
                                  checked={this.state.edtAtivo === "True"} 
                                  onChange={this.SalvaEditAtivo}  
                                  value={"True"}
                                />
                                <span className="form-check-sign" />
                                Sim
                              </Label>
                            </FormGroup>
                        </Col>
                        <Col className="checkbox-radios" sm="10">
                            <FormGroup check className="form-check-radio">
                              <Label check>
                                <Input
                                  id="vAtivo"
                                  name="vAtivo"
                                  type="radio"
                                  checked={this.state.edtAtivo === "False"}
                                  onChange={this.SalvaEditAtivo}
                                  value={"False"}
                                />
                                <span className="form-check-sign" />
                                Não
                              </Label>
                            </FormGroup>
                        </Col>

                    </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          //    success={this.state.vPlaca}
                          //error={minLengthState === "error"}
                          id="edtVeiculo"
                          name="edtVeiculo"
                          labelText="Veículo"
                          //style={{ width: "100%" }}
                          onChange={this.SalvaEditVeiculo}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              this.SalvaEditVeiculo(event);
                            },
                            value: this.state.edtVeiculo,
                            type: "text",
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          //success={this.state.vPlaca}
                          //error={minLengthState === "error"}
                          id="edtMotorista"
                          name="edtMotorista"
                          labelText="Motorista"
                          //style={{ width: "100%" }}
                          onChange={this.edtMotorista}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              this.SalvaEditMotorista(event);
                            },
                            value: this.state.edtMotorista,
                            type: "text",
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                  <legend>Limites em Valores</legend>
                    <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                              //success={this.state.vPlaca}
                              //error={minLengthState === "error"}
                              id="edtLimitePorFatura"
                              name="edtLimitePorFatura"
                              labelText="Limite Por Fatura "
                              style={{ width: "100%" }}
                              onChange={this.edtLimiteR}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                onChange: (event) => {
                                  this.SalvaLimiteR(event);
                                },
                                value: this.state.edtLimiteR,
                                type: "number",
                              }}
                            />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                              //success={this.state.vPlaca}
                              //error={minLengthState === "error"}
                              id="edtLimiteUtilizado"
                              name="edtLimiteUtilizado"
                              labelText="Limite Utilizado "
                              style={{ width: "100%" }}
                              //onChange={this.edtMotorista}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                // onChange: (event) => {
                                //   this.SalvaEditMotorista(event);
                                // },
                                value: this.state.edtUsadoR,
                                type: "number",
                              }}
                            />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                              //success={this.state.vPlaca}
                              //error={minLengthState === "error"}
                              id="edtLimiteDisponivel"
                              name="edtLimiteDisponivel"
                              labelText="Limite Disponível "
                              style={{ width: "100%" }}
//                              onChange={this.edtMotorista}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                // onChange: (event) => {
                                //   this.SalvaEditMotorista(event);
                                // },
                                 value: this.state.edtLimiteDisp,
                                type: "number",
                              }}
                            />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                  <legend>Limites em Litros</legend>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                                //success={this.state.vPlaca}
                                //error={minLengthState === "error"}
                                id="edtLitros"
                                name="edtLitros"
                                labelText="Litros por Fatura"
                                style={{ width: "100%" }}
                                onChange={this.edtLitros}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (event) => {
                                    this.SalvaLitros(event);
                                  },
                                  value: this.state.edtLitros,
                                  type: "number",
                                }}
                              />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                                //success={this.state.vPlaca}
                                //error={minLengthState === "error"}
                                id="edtLitrosUsados"
                                name="edtLitrosUsados"
                                labelText="Litros Utilizados"
                                style={{ width: "100%" }}
                               // onChange={this.edtLitrosUsados}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  // onChange: (event) => {
                                  //   this.SalvaLitros(event);
                                  // },
                                  value: this.state.edtLitrosUsados,
                                  type: "number",
                                }}
                              />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                                //success={this.state.vPlaca}
                                //error={minLengthState === "error"}
                                id="edtLitrosDisp"
                                name="edtLitrosDisp"
                                labelText="Litros Disponível"
                                style={{ width: "100%" }}
                               // onChange={this.edtLitrosUsados}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  // onChange: (event) => {
                                  //   this.SalvaLitros(event);
                                  // },
                                  value: this.state.edtLitrosDisp,
                                  type: "number",
                                }}
                              />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </Row>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <Button color="primary" className="marginLeft" onClick={ this.voltar }>
                          Voltar
                        </Button>
                        </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <Button color="success" className="marginLeft" onClick={() => this.opensweetalert()}>
                          Salvar
                        </Button>
                      </GridItem>
                    </GridContainer>
                </GridItem>

              </CardBody>
              <CardFooter stats>
                <DateRange />
                Atualizando dados da placa.
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default EditarPlaca;
