import { LaptopWindows } from '@material-ui/icons';
import React, { Component } from 'react';
import auth from '../services/auth'
import history from "./../history";

class UnidSel extends Component {

    state = {
        data: {
            idUnidOPeVinculo: '',
            unidOpe: 'sel',
        }
    };
   
    async getDadosUnidade() {
        const chave = localStorage.getItem('lukos-token-fw');
        const response = await auth.get('/faturaweb/unidsel', {
            headers: {
                token: chave
            }
        })
            console.log(response.data);
           this.setState({ data: response.data })
    };

    async componentDidMount() {
        await this.getDadosUnidade();
    };

    render() {
        return (<div>{this.state.data.unidOpe}</div>    
        );
    }

};

export default UnidSel;