import React, { Component, } from "react";
import Datetime from "react-datetime";
import auth from "services/auth";
import { Servidor } from "services/auth";
import GridContainer from "components//Grid/GridContainer.js";
import GridItem from "components//Grid/GridItem.js";
//import Card from "../Card/Card.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import checatoken from "./checatoken";
import CardBody from "components/Card/CardBody.js";
import NavPills from "components/NavPills/NavPills.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Assignment from "@material-ui/icons/Assignment";
import TabelaExtratoCupom from "./TabelaExtratoCupom";
import TabelaExtratoProduto from "./TabelaExtratoProduto";
import Primary from "components/Typography/Primary.js";
import Muted from "components/Typography/Muted";
import Button from "components/CustomButtons/Button.js";
import classNames from "classnames";
import { Label, Row, Col, Input, FormGroup, Container } from "reactstrap";
import Icon from "@material-ui/core/Icon";
import CardFooter from "components/Card/CardFooter.js";
import DateRange from "@material-ui/icons/DateRange";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

export const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

const flexContainerClasses = classNames({
  [classNames.flexContainer]: true,
  [classNames.horizontalDisplay]: true,
});

class ExtratoConsumo extends Component {

  constructor(props) {
    super(props);

    const dataAtual = new Date();
    const dia = String(dataAtual.getDate()).padStart(2, '0');
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    const ano = dataAtual.getFullYear();
    //const dataFormatada = `${ano}-${mes}-${dia}`;
    const dataFormatada = `${dia}/${mes}/${ano}`;


    this.state = {
      motorista: [],
      faturas: [],
      produtos: [],
      edtPlaca: "",
      FechInicio: "",
      FechTermino: ""
    };


    this.SalvaFechInicio = this.SalvaFechInicio.bind(this);
    this.SalvaFechTermino = this.SalvaFechTermino.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.SalvaEditplaca = this.SalvaEditplaca.bind(this);
    
  }

  SalvaFechTermino(date) {
    try {
      this.setState({ FechTermino: date.format('YYYY-MM-DD') });
    }
    catch (error) {
      this.setState({ FechTermino: '' });
    }
  }

  SalvaFechInicio(date) {
    try {
      this.setState({ FechInicio: date.format('YYYY-MM-DD') });
    }
    catch (error) {
      this.setState({ FechInicio: '' });
    }
  }

  
  SalvaEditplaca(event) {
    this.setState({ edtPlaca: event.target.value.toUpperCase() });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.getExtratoCupom();
    this.getExtratoProdutos();
  }

  async componentDidMount() {
    checatoken();
    // const dataAtual = new Date();
    // await this.getExtratoCupom();
  }

  async getExtratoCupom() {
    const chave = localStorage.getItem("lukos-token-fw");
    const response = await auth.get("/faturaweb/GetExtratoCupom", {
      headers: {
        token: chave,
        placa: this.state.edtPlaca,
        FechIni: this.state.FechInicio,
        FechTer: this.state.FechTermino,
        'Cache-Control': 'no-cache'
      },
    });
    console.log('OK')
    this.setState({ faturas: response.data })
  }

  
  async getExtratoProdutos() {
    const chave = localStorage.getItem("lukos-token-fw");
    const response = await auth.get("/faturaweb/GetExtratoprodutos", {
      headers: {
        token: chave,
        placa: this.state.edtPlaca,
        FechIni: this.state.FechInicio,
        FechTer: this.state.FechTermino,
        'Cache-Control': 'no-cache'
      },
    });
    console.log('OK produtos');
    this.setState({ produtos: response.data })
  }


  async GetExtratoCupomXLS(){
      console.log('extrato cupom');
     const chave = localStorage.getItem("lukos-token-fw");
     await auth.get("/faturaweb/GetExtratoCupomXLS", {
       headers: {
            token: chave,
            placa: this.state.edtPlaca,
            FechIni: this.state.FechInicio,
            FechTer: this.state.FechTermino,
            'Cache-Control': 'no-cache'
       }, }).then(response => {
                      console.log('ok - extrato'+response.data);
                      window.open( Servidor+'/faturaweb/GetFile/'+response.data, response.data);
              })
     .catch(error => {
       console.log(error);
       console.log('Erro Extrato');
       Swal.fire('', error.response.data, 'error');
    }) 

  }  

  async GetExtratoProdutoXLS(){
    const chave = localStorage.getItem("lukos-token-fw");
    await auth.get("/faturaweb/GetExtratoProdutoXLS", {
      headers: {
           token: chave,
           placa: this.state.edtPlaca,
           FechIni: this.state.FechInicio,
           FechTer: this.state.FechTermino,
           'Cache-Control': 'no-cache'
      }, }).then(response => {
                     console.log(response.data);
                     window.open( Servidor+'/faturaweb/GetFile/'+response.data, response.data);
             })
    .catch(error => {
      console.log('Erro Extrato');
      console.log(error.response.data);
      Swal.fire('', error.response.data, 'error');
   }) 

 }  




  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" stats icon>
              </CardHeader>
              <CardBody>
                <Primary>
                  <h4>Data do Cupom</h4>
                </Primary>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <br />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <InputLabel style={{ minWidth: 90 }}>Data Inicial</InputLabel>
                      <FormControl fullWidth style={{ maxWidth: "calc(100% - 90px)" }}>
                        <Datetime
                          id="edtFechInicio"
                          name="edtFechInicio"
                          closeOnSelect="true"
                          locale="pt-br"
                          dateFormat="DD/MM/yyyy"
                          onChange={this.SalvaFechInicio}
                          timeFormat={false}
                          inputProps={{ placeholder: "Escolha a data inicial" }}
                        />
                      </FormControl>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <br />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <InputLabel style={{ minWidth: 90 }}>Data Final</InputLabel>
                      <FormControl fullWidth style={{ maxWidth: "calc(100% - 90px)" }}>
                        <Datetime
                          id="edtFechTermino"
                          name="edtFechTermino"
                          closeOnSelect="true"
                          locale="pt-br"
                          dateFormat="DD/MM/yyyy"
                          onChange={this.SalvaFechTermino}
                          timeFormat={false}
                          inputProps={{ placeholder: "Escolha a data Final" }}
                        />
                      </FormControl>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <InputLabel style={{ marginRight: 16 }} >Placa  </InputLabel>
                      <FormControl fullWidth>
                        <CustomInput
                          success={this.state.vPlaca}
                          //error={minLengthState === "error"}
                          id="edtPlaca"
                          name="edtPlaca"
                          labelText="Placa"
                          style={{ width: "100%" }}
                          onChange={this.SalvaEditplaca}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              this.SalvaEditplaca(event);
                            },
                            value: this.state.edtPlaca,
                            type: "text",
                          }}
                        />
                      </FormControl>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <GridContainer justify="center"
                      alignItems="center"
                      direction="row" >
                      <Button color="behance" width="100%" onClick={this.handleSubmit} style={{ marginTop: '35px', marginBottom: '25px' }}> Localizar  </Button>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Assignment />
                </CardIcon>
                <Muted>
                  <h4>Cupons do Período</h4>
                </Muted>
                <Button style={{ float: 'right' }}
                color="success"
                className="marginLeft"
                onClick={() => this.GetExtratoCupomXLS()}
              >
                Baixar em Excel
              </Button>

                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <Muted>
                        <h6>Cupons Faturados</h6>
                      </Muted>

                    </GridItem>
                  </GridContainer>
                </GridItem>
              </CardHeader>
              <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
                <CardBody>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <TabelaExtratoCupom faturas={this.state.faturas} />
                    </GridContainer>
                  </GridItem>
                </CardBody>
              </div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}></GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <Assignment />
                </CardIcon>
                <Muted>
                  <h4>Produtos no Período</h4>
                </Muted>
                <Button style={{ float: 'right' }}
                color="success"
                className="marginLeft"
                onClick={() => this.GetExtratoProdutoXLS()}
              >
                Baixar em Excel
              </Button>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <Muted>
                        <h6>Produtos consumidos no período</h6>
                      </Muted>

                    </GridItem>
                  </GridContainer>
                </GridItem>
              </CardHeader>
              <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
                <CardBody>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <TabelaExtratoProduto faturas={this.state.produtos} />
                    </GridContainer>
                  </GridItem>
                </CardBody>
              </div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}></GridItem>

        </GridContainer>
      </div>
    );
  }
}

export default ExtratoConsumo;
