import React, { Component } from "react";
// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import PropTypes from "prop-types";
import _ from "lodash";

// core components
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import MediaQuery from 'react-responsive'



class TabelaExtratoProduto extends Component {
  constructor(props) {
    super(props);
    this.state = { frota: [] };
  }

  state = {
    color: 0,
  }


  async componentDidMount() {

  }

  render() {
    return (<div>
      <MediaQuery minWidth={1224}>
        {/* // <p>Desktop</p> */}

        <ReactTable
          data={this.props.faturas.map((prop, key) => {
            return {
              id: key,
              idCupom: prop[0],
              DataCupom: prop[1],
              COO: prop[2],
              Placa: prop[3],
              km: prop[4],
              Motorista: prop[5],
              CodProduto: prop[6],
              Produto: prop[7],
              qCom: prop[8],
              vUnitario: prop[9],
              Desconto: prop[10],
              TotalItem: prop[11],
              idCupomItem: prop[12],
              actions: (
                <div className="actions-right" style={{ width: "100px"}} >
                  {/* <Button
                    justIcon
                    round
                    simple
                    onClick={() =>
                      window.location.href = '/admin/visualizarfatura/' + prop[0]
                    }
                    color="warning"
                    className="edit"
                    
                  >
                    <Dvr />
                  </Button>{" "} */}
                </div>
              )
            };
          })}
          columns={[//{ Header: "Cód Cupom", accessor: "idCupom"},
          { Header: "Nº Fiscal", accessor: "COO", },
          { Header: "Data Venda", accessor: "DataCupom" },
          { Header: "Placa", accessor: "Placa", },
          { Header: "Cód. Produto", accessor: "CodProduto", },
          { Header: "Produto", accessor: "Produto", },
          { Header: "Qtde", accessor: "qCom", },
          { Header: "$ Unit", accessor: "vUnitario", },
          { Header: "Desconto", accessor: "Desconto", },
          { Header: "Total Item", accessor: "TotalItem", },
          //{ Header: "idCupomItem", accessor: "idCupomItem", },
          { Header: "", accessor: "actions", sortable: false, filterable: false}
          ]}
        />
      </MediaQuery>
      <MediaQuery maxWidth={1223}>
        <p>Mobile</p>
        <ReactTable
          data={this.props.faturas.map((prop, key) => {
            return {
              id: key,
              idCupom: prop[0],
              DataCupom: prop[1],
              COO: prop[2],
              Placa: prop[3],
              km: prop[4],
              Motorista: prop[5],
              CodProduto: prop[6],
              Produto: prop[7],
              qCom: prop[8],
              vUnitario: prop[9],
              Desconto: prop[10],
              TotalItem: prop[11],
              idCupomItem: prop[12],
              actions: (
                <div className="actions-right" >
                  {/* <Button 
                    justIcon
                    round
                    simple
                    onClick={() =>
                      window.location.href = '/admin/visualizarfatura/' + prop[0]
                    }
                    color="primary"
                    className="edit"
                  >
                    <Dvr />
                  </Button>{" "} */}
                </div>
              )
            };
          })}
          columns={[
            { Header: "Data Venda", accessor: "DataCupom" },
            { Header: "Placa", accessor: "Placa", },
            { Header: "Cód. Produto", accessor: "CodProduto", },
            { Header: "Produto", accessor: "Produto", },
            { Header: "Qtde", accessor: "qCom", },
            { Header: "$ Unit", accessor: "vUnitario", },
            { Header: "Desconto", accessor: "Desconto", },
            { Header: "Total Item", accessor: "TotalItem", },
            { Header: "", accessor: "actions", sortable: false, filterable: false}
          ]}
        />
      </MediaQuery>

    </div>
    );
  }
}

TabelaExtratoProduto.propTypes = {
  faturas: PropTypes.array
}

export default TabelaExtratoProduto;
