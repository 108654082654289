import React, { Component } from "react";
import ReactDOM from "react-dom";
// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import auth from "services/auth";
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";

// reactstrap components
import {
  Input
} from "reactstrap";





class TabelaServicos  extends Component  {
  constructor(props) {
    super(props);
    this.state = { frota: [] };
  }

  state = {
      color:0,
  }

async getDadosFrota() {
    const chave = localStorage.getItem('lukos-token-fw');
    const response = await auth.get('/faturaweb/gettabelafrota', {
    headers: {
        token: chave,
        color: this.state.color
    }
});
this.setState({ frota: response.data});
}

   async componentDidMount() {

    
  }

  render() {
  return (<div>
    {/* <h1>{this.props.frota}</h1> */}
    <ReactTable
      data={this.props.servicos.map((prop, key) => {
          return {
            id: key,
            idCupom: prop[0],
            COO: <div className="actions-center">
                  {prop[1]}
                </div>,
            DataCupom: prop[2],
            Placa: prop[3],
            Serviço: prop[5],
            Quantidade: prop[6],
            ValorUnitario: prop[7],
            Desconto: prop[8],
            ValorTotal: prop[9]
          };
        })}
        columns={[
          {
            accessor: "COO",
            Header: "Nº Cupom", 

          },
          {
            Header: "Data",
            accessor: "DataCupom",
          },
         {
            Header: "Placa",
            accessor: "Placa"
          },
          {
            Header: "Serviço",
            accessor: "Servico",
          },
          {
            Header: "Qtde",
            accessor: "Quantidade",
          },
          {
            Header: "R$ Unit",
            accessor: "ValorUnitario",
          },
          {
            Header: "Desconto",
            accessor: "Desconto",
          },

          {
            Header: "R$ Total",
            accessor: "ValorTotal",
          },

          {
            Header: ".",
            accessor: "",
          },         
        ]}
      />

      </div>
  );
}
}

TabelaServicos.propTypes = {
  servicos: PropTypes.array}

export default TabelaServicos;
